<template>
	<div>
		<Header :childIndex="0"></Header>
		<CutImg ref='CutImgPop' :cutWidth='150' :cutHeight='150' :cutType='1'></CutImg>
		<div class="main_box">
			<div class="main_boxc">
				<div class="title_box">
					<router-link :to="{path:'/'}">
						<p>首页 > </p>
					</router-link>
					<span>个人中心</span>
				</div>
				<div class="cont_box">
					<UserLeft :leftIndex='4' :childIndex="0"></UserLeft>
					<div class="cont_right_box">
						<div class="listtitle_box">
							<i></i>
							<img src="../../../images/grzl_ico.png">
						</div>
						<div class="personaldata_box">
							<div class="personaldata_boxc">
								<div class="person_left">
									<img v-if="userAvtor" :src="$ImgUrl + userAvtor" />
									<img v-if="!userAvtor" src="../../../images/morentouxiang.png" />
									<div class="edit_header">
										<input type="file" name="upload" accept="image/bmp,image/jpeg,image/jpg,image/png" @change="uploadImg($event,1)" />
										<span>更换头像</span>
									</div>
									<div class="vip_box" v-if="userInfo.ServiceType == 1">
										<img src="../../../images/vip_ico.png" />
									</div>
								</div>
								<div class="person_right">
									<i>
										<span class="jgyh">{{userInfo.UserType == 0?'个人用户':'机构用户'}}</span>
										<h2>{{userInfo.UserName}}</h2>
									</i>
									<br />
									<i v-if="userInfo.ServiceType == 1">
										<p>会员到期时间:<font>{{userInfo.ServiceDate | GetYMDHMS('yyyy-MM-dd')}}</font>
										</p>
									</i>
								</div>
							</div>
						</div>
						<div class="personaldata_bottom">
							<div class="change_c_box m_t30 m_b30 w692px">
								<ul>
									<li>
										<p>类 型</p>
										<input type="text" :value="userInfo.UserType == 0?'个人用户':'机构用户'" disabled="disabled" />
									</li>
									<li>
										<p>机构名称</p>
										<input type="text" v-model="orgName" />
										<span id="orgName" class="zxtgerror"></span>
									</li>
									<li>
										<p>联系人</p>
										<input type="text" v-model="orgContact" />
										<span id="orgContact" class="zxtgerror"></span>
									</li>
									<li>
										<p>联系方式</p>
										<input type="text" v-model="orgContactName" />
										<span id="orgContactName" class="zxtgerror"></span>
									</li>
									<li>
										<p>所在地</p>
										<v-distpicker @selected="GetAddress"  hide-area></v-distpicker>
									</li>
									<li>
										<p>机构简介</p>
										<textarea v-model="orgIntro"></textarea>
										<span id="orgIntro" class="zxtgerror"></span>
									</li>
								</ul>
							</div>
							<div class="btn_box">
								<a href="javascript:void(0)" @click="SaveEdit">保存</a>
								<a href="javascript:void(0)" @click="CancelEdit">取消</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script src="./EditOrgUser.js">
</script>

<style>
	.distpicker-address-wrapper select{ outline: none; background: #fbfbfb;}
</style>
